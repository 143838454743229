exports.components = {
  "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-footer-tsx": () => import("./../../../node_modules/@onegeo-suite/gatsby-theme-onegeo/src/pages/footer.tsx" /* webpackChunkName: "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-footer-tsx" */),
  "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-header-tsx": () => import("./../../../node_modules/@onegeo-suite/gatsby-theme-onegeo/src/pages/header.tsx" /* webpackChunkName: "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-header-tsx" */),
  "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-index-tsx": () => import("./../../../node_modules/@onegeo-suite/gatsby-theme-onegeo/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-index-tsx" */),
  "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-news-tsx": () => import("./../../../node_modules/@onegeo-suite/gatsby-theme-onegeo/src/pages/news.tsx" /* webpackChunkName: "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-pages-news-tsx" */),
  "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-templates-news-tsx": () => import("./../../../node_modules/@onegeo-suite/gatsby-theme-onegeo/src/templates/news.tsx" /* webpackChunkName: "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-templates-news-tsx" */),
  "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-templates-page-tsx": () => import("./../../../node_modules/@onegeo-suite/gatsby-theme-onegeo/src/templates/page.tsx" /* webpackChunkName: "component---node-modules-onegeo-suite-gatsby-theme-onegeo-src-templates-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-sample-index-tsx": () => import("./../../../src/pages/sample.index.tsx" /* webpackChunkName: "component---src-pages-sample-index-tsx" */)
}

